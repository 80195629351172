<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col sm="8" align="center">
        <v-card class="pa-5">
          <v-row>
            <v-col class="mx-auto" align="center" sm="10">
              <img src="../../assets/identity-logo.png" height="64px" />
              <h4 style="height: 100%; vertical-align: middle"
                >This application uses the Wyoming Identity System for
                authentication if you already have an account with the Identity
                System leave the password field blank when registering to link
                your accounts.</h4
              >
            </v-col>
          </v-row>
          <v-form ref="form" lazy-validation v-model="valid">
            <v-row>
              <v-col xs12>
                <v-text-field
                  label="First Name"
                  v-model="user.FirstName"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs12>
                <v-text-field
                  label="Last Name"
                  v-model="user.LastName"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs12>
                <v-text-field
                  label="Email"
                  v-model="user.Email"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col xs12>
                <span
                  >If the email you have enter is a Google email, you may sign
                  in without a password by click 'Sign In With Google' when you
                  log in, if it is not a Google email or you would prefer to
                  sign in with a password, type a password below. Otherwise
                  leave this field blank.</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col xs12>
                <v-text-field
                  label="Password"
                  v-model="user.Password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  :rules="[
                    (v) =>
                      !v ||
                      passValid ||
                      'Passwords must be at least 13 characters long, contain upper and lowercase letters, have at last one number, and one special character',
                  ]"
                  hint="Passwords must be at least 13 characters long, contain upper and lowercase letters, have at last one number, and one special character"
                  :type="show ? 'text' : 'password'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="ma-2">
              <v-col align="center">
                <v-btn
                  class="ma-2"
                  color="primary"
                  @click="register"
                  :disabled="disableReg"
                  >Register</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="850px">
      <v-card>
        <v-card-text>
          {{ msg }}
          <div v-for="(e, idx) in errors" :key="idx">
            {{ e }}
            <br />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
const moduleNamespace = 'user'
export default {
  page: {
    title: 'Register',
  },
  name: 'Register',
  data() {
    return {
      user: {},
      dialog: false,
      msg: '',
      errors: [],
      loading: false,
      show: false,
      valid: true,
      passRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d])[.\S]{13,}$/,
    }
  },
  methods: {
    async validate() {
      await this.$refs.form.validate()
    },
    async register() {
      var valid = await this.validate()

      if (this.valid) {
        this.user.UserName = this.user.Email
        this.loading = true
        try {
          let { data } = await this.$axios.post(`Register`, this.user)
          this.user = {}
          this.msg = ''
          this.errors = []
          this.msg = `You have been successfully registered: ${data}`
          this.dialog = true
          this.loading = false
        } catch (err) {
          // this.handleError(err)
          this.msg = ''
          this.errors = []
          let e = err.response.data
          console.devlog(e)
          if (typeof e == 'string') {
            this.errors.push(e)
          } else if (Array.isArray(e)) {
            e.forEach((val) => {
              this.errors.push(val)
            })
          } else if (e === Object(e)) {
            Object.keys(e).forEach((val) => {
              if (Array.isArray(e[val])) {
                this.errors.push(e[val][0])
              } else {
                this.errors.push(e[val])
              }
            })
          }
          this.msg = `There was a problem completing your registration: `
          this.dialog = true
          this.loading = false
        }
      }
    },
  },
  computed: {
    passValid() {
      let check = this.user.Password.match(this.passRegex)
      return check && check.length > 0
    },
    disableReg() {
      if (
        this.user.Email &&
        this.user.FirstName &&
        this.user.LastName &&
        !this.loading
      ) {
        return false
      }
      return true
    },
  },
  watch: {},
  components: {},
}
</script>
<style></style>
